import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import GoogleLogin, { GoogleLoginResponse, GoogleLogout } from 'react-google-login';
import { useAuthContext } from '../contexts';
import { gapi } from 'gapi-script';
import { useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { NetworkBadge } from './NetworkBadge';
import { Action, useActionContext } from '../contexts/ActionContext';
import { useWallet } from '@solana/wallet-adapter-react';
import { formatPubkey } from '../utils/url';

const clientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ??
  '540992596258-ogaft932ucrhuclnggjup6ipf0g9mkt9.apps.googleusercontent.com';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isGoogleLoginResponse(obj: any | undefined): obj is GoogleLoginResponse {
  return obj !== null && obj !== undefined && 'googleId' in obj && 'tokenObj' in obj;
}

// Full example from: https://chakra-templates.dev/navigation/navbar
export default function NavBar() {
  const wallet = useWallet();
  const { setActionContext: setAction } = useActionContext();
  const { isOpen, onToggle } = useDisclosure();
  const { tokenId, setTokenId } = useAuthContext();

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        client_id: clientId
      });
    }
    gapi.load('client:auth2', start);
  });

  const DesktopNav = () => {
    const { pathname } = useLocation();
    return (
      <Stack direction={'row'} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>{renderNavLink(navItem, pathname)}</PopoverTrigger>
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };

  const MobileNav = () => {
    return (
      <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };

  const NavButtons = () => {
    return (
      <>
        <NetworkBadge />
        <Button
          display={{ base: 'none', sm: 'inline-flex' }}
          fontWeight={600}
          colorScheme="brand"
          onClick={() => setAction({ action: Action.menu })}
          color={'white'}
        >
          Actions
        </Button>
        {!tokenId && (
          <GoogleLogin
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                display={{ base: 'none', sm: 'inline-flex' }}
                fontWeight={600}
                colorScheme="brand"
                color={'white'}
              >
                Sign In
              </Button>
            )}
            isSignedIn={true}
            style={{
              height: '100%'
            }}
            clientId={clientId}
            buttonText="Login"
            onSuccess={(res) => {
              if (isGoogleLoginResponse(res)) {
                setTokenId(res.tokenId);
              }
            }}
            onFailure={(res) => console.log(res)}
            cookiePolicy={'single_host_origin'}
          />
        )}
        {tokenId && (
          <GoogleLogout
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                display={{ base: 'none', sm: 'inline-flex' }}
                fontWeight={600}
                colorScheme="brand"
                color={'white'}
              >
                Sign Out
              </Button>
            )}
            clientId={clientId}
            buttonText="Logout"
            onLogoutSuccess={() => {
              setTokenId(undefined);
            }}
          />
        )}
        <WalletMultiButton
          style={{
            backgroundColor: 'var(--chakra-colors-brand-200)',
            height: 'var(--chakra-sizes-10)',
            fontWeight: '600',
            fontSize: 'var(--chakra-fontSizes-md)'
          }}
        >
          <Text>{wallet.publicKey ? formatPubkey(wallet.publicKey.toString()) : 'Connect'}</Text>
        </WalletMultiButton>
      </>
    );
  };

  return (
    <Box ml={10} mr={10}>
      <Flex
        bg={'brand.900'}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        align={'baseline'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Flex display={{ base: 'none', md: 'flex' }}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          display={{ md: 'flex', sm: 'none' }}
          direction={'row'}
          spacing={4}
          justifyContent={'space-between'}
          align={'center'}
        >
          <NavButtons />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
        <Stack
          flex={{ base: 1, md: 0 }}
          display={{ md: 'none', sm: 'flex' }}
          justifyContent={'space-between'}
          direction={'row'}
          align={'center'}
        >
          <NavButtons />
        </Stack>
      </Collapse>
    </Box>
  );
}

const renderNavLink = (navItem: NavItem, pathname: string) => {
  return (
    <Link to={navItem.href ?? ''}>
      <Text fontWeight={600} color={pathname === navItem.href ? 'brand.100' : 'gray.200'}>
        {navItem.label}
      </Text>
    </Link>
  );
};

const MobileNavItem = (navItem: NavItem) => {
  const { pathname } = useLocation();
  return (
    <Stack spacing={4}>
      <Flex
        to={navItem.href ?? ''}
        py={2}
        as={Link}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none'
        }}
      >
        {renderNavLink(navItem, pathname)}
      </Flex>
    </Stack>
  );
};

interface NavItem {
  label: string;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Vaults',
    href: '/vaults'
  },
  {
    label: 'Proto Configs',
    href: '/protoconfigs'
  },
  {
    label: 'Positions',
    href: '/positions'
  },
  {
    label: 'Active Wallets',
    href: '/activewallets'
  }
];
