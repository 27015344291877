import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  VStack
} from '@chakra-ui/react';
import { ExpandedAdminVault, V1AdminVaultsGetExpandEnum } from '@dcaf-labs/drip-ts';
import _ from 'lodash';
import { FC, useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';
import { TokenPairCard } from '../component/TokenPairCard';
import { VaultsTable } from '../component/VaultsTable';
import { useAuthContext } from '../contexts';
import { useRefreshContext } from '../contexts/Refresh';
import { useAdminApi } from '../hooks/AdminApi';
import { useTxToast } from '../hooks/TxToast';

export const Vaults: FC = () => {
  const refreshContext = useRefreshContext();

  const adminApi = useAdminApi();
  const auth = useAuthContext();
  const txToast = useTxToast();

  const [filterVault, setFilterVault] = useState<string | undefined>(undefined);
  const [filterVaultProtoConfig, setFilterVaultProtoConfig] = useState<string | undefined>(
    undefined
  );
  const [filterTokenA, setFilterTokenA] = useState<string | undefined>(undefined);
  const [filterTokenB, setFilterTokenB] = useState<string | undefined>(undefined);
  const [filterEnabled, setFilterEnabled] = useState<boolean | undefined>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [excludeKeys, setExcludeKeys] = useState<string>(
    'BK7m7aEb5SrJBSAzMBGumnRhRvF3C7j7fssUrPKPTwxG 6i3GfRb48FtUYB1e3UqfamT1D52UtGtTkF6tMF1F6YHv'
  );

  const excludeKeysSet = new Set(excludeKeys.split(' '));

  const vaults = useAsyncMemo(async () => {
    setIsLoading(true);
    let vaults;
    try {
      vaults = await adminApi.v1AdminVaultsGet({
        tokenId: auth.tokenId ?? '',
        expand: [V1AdminVaultsGetExpandEnum.All],
        enabled: filterEnabled,
        vault: filterVault ? filterVault : undefined,
        vaultProtoConfig: filterVaultProtoConfig ? filterVaultProtoConfig : undefined,
        tokenA: filterTokenA ? filterTokenA : undefined,
        tokenB: filterTokenB ? filterTokenB : undefined
        // TODO(Matcha): Paginate
        // offset?: number;
        // limit?: number;
      });
    } catch (e) {
      txToast.failure(e as Error);
    }
    setIsLoading(false);
    return vaults;
  }, [
    adminApi,
    auth,
    filterVault,
    filterVaultProtoConfig,
    filterTokenA,
    filterTokenB,
    filterEnabled,
    filterEnabled,
    refreshContext.refreshTrigger
  ]);

  if (isLoading) {
    return (
      <Center h="calc(100vh - 180px)">
        <VStack spacing="20px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#62aaff" size="xl" />
        </VStack>
      </Center>
    );
  }
  const expandedAdminVaults = vaults?.filter((el: ExpandedAdminVault) => {
    if (
      excludeKeysSet.has(el.pubkey) ||
      excludeKeysSet.has(el.protoConfig) ||
      excludeKeysSet.has(el.tokenAMint) ||
      excludeKeysSet.has(el.tokenBMint) ||
      excludeKeysSet.has(el.tokenAAccount) ||
      excludeKeysSet.has(el.tokenBAccount) ||
      excludeKeysSet.has(el.treasuryTokenBAccount)
    ) {
      return false;
    }
    return true;
  });

  const vaultsByTokenPair = _.groupBy(expandedAdminVaults, (el: ExpandedAdminVault) => {
    return el.tokenAMint + '-' + el.tokenBMint;
  });
  const vaultsTableByTokenPair = _.mapValues(
    vaultsByTokenPair,
    function (el: ExpandedAdminVault[]) {
      return <VaultsTable vaults={el} />;
    }
  );

  const tokenPairs = _.uniqBy(
    expandedAdminVaults?.map((el) => {
      return {
        tokenAMint: el.tokenAMint,
        tokenASymbol: el.tokenAMintValue?.symbol,
        tokenAIconUrl: el.tokenAMintValue?.iconUrl,
        tokenBMint: el.tokenBMint,
        tokenBSymbol: el.tokenBMintValue?.symbol,
        tokenBIconUrl: el.tokenBMintValue?.iconUrl
      };
    }),
    (el) => {
      return el.tokenAMint + el.tokenBMint;
    }
  );

  return (
    <Box width={'100%'}>
      <Accordion allowToggle={true} width={'100%'}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by vault"
              size="lg"
              value={filterVault}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if ((e.target as HTMLInputElement).value === '') {
                  setFilterVault(undefined);
                } else {
                  setFilterVault((e.target as HTMLInputElement).value ?? undefined);
                }
              }}
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by vault proto config"
              size="lg"
              value={filterVaultProtoConfig}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if ((e.target as HTMLInputElement).value === '') {
                  setFilterVaultProtoConfig(undefined);
                } else {
                  setFilterVaultProtoConfig((e.target as HTMLInputElement).value ?? undefined);
                }
              }}
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by token a mint"
              size="lg"
              value={filterTokenA}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setFilterTokenA((e.target as HTMLInputElement).value ?? undefined)
              }
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <Input
              placeholder="filter by token b mint"
              size="lg"
              value={filterTokenB}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setFilterTokenB((e.target as HTMLInputElement).value ?? undefined)
              }
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <Input
              placeholder="exclude any of"
              size="lg"
              value={excludeKeys}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setExcludeKeys((e.target as HTMLInputElement).value ?? undefined)
              }
            />
          </AccordionPanel>

          <AccordionPanel pb={4}>
            <RadioGroup
              onChange={(val: string | number | undefined) => {
                if (val === '1') {
                  setFilterEnabled(undefined);
                } else if (val === '2') {
                  setFilterEnabled(true);
                } else {
                  setFilterEnabled(false);
                }
              }}
              value={filterEnabled === undefined ? '1' : filterEnabled ? '2' : '3'}
            >
              <Stack direction="row">
                <Radio value={'1'}>All</Radio>
                <Radio value={'2'}>Enabled</Radio>
                <Radio value={'3'}>Disabled</Radio>
              </Stack>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {tokenPairs?.map((tokenPair) => {
        const key = tokenPair.tokenAMint + '-' + tokenPair.tokenBMint;
        return <TokenPairCard key={key} {...tokenPair} children={vaultsTableByTokenPair[key]} />;
      })}
    </Box>
  );
};
